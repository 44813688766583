import React from 'react';
import Header from '../components/header';
import { Avatar, Box, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import userService from 'service/userService';
import httpService from 'service/httpService';
import getAlphabethColor from 'lib/helpers/colorSelect';
import BTNLoader from 'lib/components/Preloaders/Btn';
import { Skeleton } from '@material-ui/lab';
import { PATHNAMES } from 'lib/constants/pathnames';
import { useHistory } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import usePost from 'hooks/usePost';
import EmptyState11 from 'lib/assets/images/empty-state-11.png';

const LINK = `${location?.pathname}${location.search}`;
const REDIRECT = `${PATHNAMES.ACCOUNT_VERIFICATION}?type=new&redirect=${LINK}`;

const Organizations = () => {
  const { user } = useUserData();
  const history = useHistory();

  React.useEffect(() => {
    if (!user) return;
    if (!user?.isVerified) history.replace(REDIRECT);
  }, [user]);

  const invitationsQuery = useFetch({
    qKey: 'invitations',
    requestFn: () => userService.getInvites(),
  });
  const organizationsQuery = useFetch({
    qKey: 'organizations',
    requestFn: () => userService.getOrgs(),
  });

  const acceptInviteMutation = usePost({
    requestFn: (id) => userService.acceptInvite(id),
    onSuccess: (data) => {
      httpService.toast.success('Invitation accepted successfully.');
      invitationsQuery.refetch();
      organizationsQuery.refetch();
    },
  });
  const rejectInviteMutation = usePost({
    requestFn: (id) => userService.rejectInvite(id),
    onSuccess: (data) => {
      httpService.toast.success('Invitation rejected successfully.');
      invitationsQuery.refetch();
    },
  });

  const invitations = invitationsQuery?.data?.data || [];
  const organizations = organizationsQuery?.data?.data || [];
  const isFetching = organizationsQuery.isLoading;
  const isAccepting = acceptInviteMutation.isLoading;
  const isRejecting = rejectInviteMutation.isLoading;
  console.log(organizations);
  return (
    <div className="container-xxl">
      <Header />
      <div>
        <div className="d-flex justify-content-end gap-3 my-3 mb-5">
          <Button
            component={Link}
            to={PATHNAMES.REFERRAL}
            variant="outlined"
            color="primary">
            Refer Business
          </Button>
          <Button
            component={Link}
            to={PATHNAMES.CREATE_ORGANIZATIONS}
            endIcon={<Add />}
            variant="contained"
            color="primary">
            Create Organization
          </Button>
        </div>
        <div className="p-5 border rounded mx-auto mb-5">
          <div className="text-center fw-semibold fs-3">
            Welcome {user?.firstName}
          </div>
          <div className="mx-auto" style={{ maxWidth: 600 }}>
            {!isFetching && !invitations?.length && !organizations?.length ? (
              <div className="text-center">
                You haven't created any organizations yet. Tap the{' '}
                <Link
                  to={PATHNAMES.CREATE_ORGANIZATIONS}
                  className="text-primary fw-medium">
                  "Create New Organization"
                </Link>{' '}
                button below to get started, or refer a business to join our
                referral program!
              </div>
            ) : (
              <div className="text-center">
                Below are the list of organization associated to this account,
                what organization would you like to sign into?
              </div>
            )}
          </div>

          <Preloader
            isLoading={
              isFetching && !invitations?.length && !organizations?.length
            }
          />

          {!isFetching && !invitations?.length && !organizations?.length ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="mt-5 d-flex justify-content-center mb-5">
                <img src={EmptyState11} alt="No Data" />
              </div>
              <Button
                component={Link}
                to={PATHNAMES.CREATE_ORGANIZATIONS}
                endIcon={<Add />}
                variant="contained"
                color="primary">
                Create a new organization
              </Button>
            </div>
          ) : null}

          <div className="row justify-content-md-center mt-5">
            {invitations?.map((item) => (
              <div className="col-12 col-md-4 col-lg-3 mb-3" key={item?.id}>
                <OrganizationCard
                  type="invitation"
                  status={item?.status}
                  isAccepting={isAccepting}
                  isRejecting={isRejecting}
                  name={item?.organizationMeta?.name}
                  role={getRole(item?.roleName || '')}
                  onAccept={() => acceptInvite(item?.id)}
                  onReject={() => rejectInvite(item?.id)}
                  image={item?.organizationMeta?.logoUploadURL}
                />
              </div>
            ))}
            {organizations?.map((item) => (
              <div className="col-12 col-md-4 col-lg-3 mb-3" key={item?.id}>
                <OrganizationCard
                  name={item?.name}
                  image={item?.logoUploadMeta?.url}
                  subscription={item?.subscriptionMeta}
                  role={getRole(item?.profile?.roleName || '')}
                  onEnter={() => handleSelect(item)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  async function acceptInvite(id) {
    acceptInviteMutation.mutate(id);
  }

  async function rejectInvite(id) {
    rejectInviteMutation.mutate(id);
  }

  function handleSelect(data) {
    localStorage.setItem('organizationId', data?.id);
    localStorage.setItem('ORGANIZATION', JSON.stringify(data));
    location.assign('/dashboard');
  }
};

function getRole(role) {
  switch (role) {
    case 'SUPER_USER':
      return 'Super User';
    case 'SUB_ADMIN':
      return 'Sub Admin';
    case 'ACCOUNTANT':
      return 'Accountant';
    default:
  }
}

function Preloader({ isLoading }) {
  if (!isLoading) return null;

  return (
    <div className="row justify-content-md-center mt-5">
      {[1, 2, 3, 4].map((item) => (
        <div className="col-12 col-md-4 col-lg-3" key={item}>
          <div
            className="d-flex flex-column align-items-center w-100"
            style={{ padding: 16, border: '1px solid #ccc', borderRadius: 10 }}>
            <Skeleton
              variant="circle"
              height={100}
              width={100}
              style={{ marginBottom: 20 }}
            />
            <div className="w-100">
              <Skeleton variant="h1" height={20} style={{ marginBottom: 20 }} />
              <Skeleton variant="text" style={{ marginBottom: 20 }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function OrganizationCard({
  type = '',
  image,
  name,
  role,
  onReject,
  onAccept,
  onEnter,
  isAccepting,
  isRejecting,
  subscription,
  status,
}) {
  return (
    <div className="border rounded p-3 position-relative">
      {type !== 'invitation' ? null : (
        <div className="position-absolute" style={{ top: -10, right: -10 }}>
          <span className="badge text-bg-warning rounded-pill">Pending</span>
        </div>
      )}
      {type === 'invitation' ? null : !subscription ? (
        <div className="position-absolute" style={{ top: -10, right: -10 }}>
          <span className="badge text-bg-warning text-white rounded-pill">
            No Subscription
          </span>
        </div>
      ) : subscription?.status === 'EXPIRED' ? (
        <div className="position-absolute" style={{ top: -10, right: -10 }}>
          <span className="badge text-bg-danger text-white rounded-pill">
            Expired
          </span>
        </div>
      ) : status === 'INACTIVE' ? (
        <div className="position-absolute" style={{ top: -10, right: -10 }}>
          <span className="badge text-bg-danger text-white rounded-pill">
            Inactive
          </span>
        </div>
      ) : (
        <div className="position-absolute" style={{ top: -10, right: -10 }}>
          <span className="badge text-bg-info text-white rounded-pill">
            {subscription?.planName}
          </span>
        </div>
      )}
      <div className="d-flex flex-column align-items-center">
        <Avatar
          className="fs-3 mb-3"
          style={{
            width: 100,
            height: 100,
            background: getAlphabethColor(name?.charAt(0)),
          }}
          src={image}>
          {name?.charAt(0)}
        </Avatar>
        <div className="text-center w-100">
          <div className="fw-semibold fs-5 mb-0 text-truncate">{name}</div>
          <p>{role}</p>
        </div>
        {type === 'invitation' ? (
          <div className="d-flex align-items-center mt-4 w-100 gap-3">
            <Button
              variant="outlined"
              onClick={onReject}
              disabled={isAccepting || isRejecting}
              startIcon={isRejecting ? <BTNLoader /> : null}
              className="rounded-pill px-4 text-danger w-100">
              Reject
            </Button>
            <Button
              onClick={onAccept}
              disabled={isAccepting || isRejecting}
              startIcon={isAccepting ? <BTNLoader color="#fff" /> : null}
              className="rounded-pill bg-success text-white w-100">
              Accept
            </Button>
          </div>
        ) : (
          <Button
            onClick={onEnter}
            variant="outlined"
            className="rounded-pill w-100 mt-4">
            Enter
          </Button>
        )}
      </div>
    </div>
  );
}

export default Organizations;
