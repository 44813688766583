import { yupResolver } from '@hookform/resolvers/yup';
import { Button, IconButton, Paper, TextField } from '@material-ui/core';
import Menu from 'lib/components/Menu/Menu';
import BTNLoader from 'lib/components/Preloaders/Btn';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import authService from 'service/authService';
import httpService from 'service/httpService';
import authValidation from 'validations/auth/authValidation';
import { PATHNAMES } from 'lib/constants/pathnames';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [visibility, setVisibility] = React.useState({
    password: false,
    confirmPassword: false,
  });

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const { handleSubmit, errors, control } = useForm({
    mode: 'all',
    resolver: yupResolver(authValidation.RESET_PASSWORD),
    defaultValues: initialValues,
  });
  return (
    <div className="container-xxl">
      <Menu />
      <Paper
        className="p-3 py-5 border mx-auto my-4 rounded-4"
        style={{ maxWidth: 600 }}>
        <div className="fs-4 mb-4 text-center">Reset Password</div>

        <form
          className="mx-auto"
          onSubmit={handleSubmit(save)}
          style={{ maxWidth: 350 }}>
          <div className="mb-4">
            <label>
              Enter New Password: <span className="text-danger">*</span>
            </label>
            <Controller
              name="password"
              control={control}
              render={(props) => (
                <TextField
                  type={visibility.password ? 'text' : 'password'}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['password']?.message}
                  error={!!errors['password']}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() => handleChangeVisibility('password')}>
                        {visibility.password ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <label>
              Confirm New Password: <span className="text-danger">*</span>
            </label>
            <Controller
              name="confirmPassword"
              control={control}
              render={(props) => (
                <TextField
                  type={visibility.confirmPassword ? 'text' : 'password'}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['confirmPassword']?.message}
                  error={!!errors['confirmPassword']}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleChangeVisibility('confirmPassword')
                        }>
                        {visibility.confirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="mb-5">
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
              style={{ textTransform: 'none' }}
              disabled={isLoading}
              startIcon={isLoading && <BTNLoader />}>
              Save
            </Button>
          </div>
          <div className="text-center my-2 mb-4">
            <Link className="text-primary" to={PATHNAMES.SIGN_IN}>
              Back to Login
            </Link>
          </div>
        </form>
      </Paper>
    </div>
  );

  function handleChangeVisibility(name) {
    setVisibility((prev) => ({ ...prev, [name]: !prev[name] }));
  }

  async function save(formData) {
    try {
      setIsLoading(true);
      await authService.resetPassword(token, formData);
      httpService.toast.success('Password reset successfully.');
      setTimeout(() => history.replace(PATHNAMES.SIGN_IN), 1000);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }
};

export default ResetPassword;
