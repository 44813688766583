import { Avatar } from '@material-ui/core';
import getAlphabethColor from 'lib/helpers/colorSelect';
import React, { useEffect } from 'react';

const Organization = ({ item, handleSelect }) => {
  return (
    <li
      onClick={() => handleSelect(item)}
      style={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
      <span>
        <Avatar
          style={{
            background: item?.logoUploadMeta?.url
              ? ''
              : getAlphabethColor(item?.name.charAt(0) || ''),
          }}
          src={item?.logoUploadMeta?.url}
          alt={item.name}>
          {item?.name?.charAt(0)}
        </Avatar>
      </span>
      {item?.name}
    </li>
  );
};

export default Organization;
