import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Menu from 'lib/components/Menu/Menu';
import BTNLoader from 'lib/components/Preloaders/Btn';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import authValidation from 'validations/auth/authValidation';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Google } from 'lib/assets/icons/google.svg';
import { ReactComponent as Apple } from 'lib/assets/icons/apple.svg';
import authService from 'service/authService';
import saveToken from 'utils/saveToken';
import httpService from 'service/httpService';
import { appleSignIn, googleSignIn } from 'service/firebase';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { UserContext } from 'context/user/user';
import getDeviceDetails from 'utils/getDeviceInfo';
import { PATHNAMES } from 'lib/constants/pathnames';
import useUserData from 'hooks/useUserData';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  linear: {
    height: '5px',
    borderRadius: '5px',
    backgroundColor: '#edeeef',
    marginBottom: '10px',
  },
  linearBar: {
    borderRadius: '5px',
    backgroundColor: '#1f53d7',
    color: '#1f53d7',
  },
}));

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  referrerCode: '',
  terms: false,
};

const authTypes = {
  DEFAULT: 'DEFAULT',
  GOOGLE: 'GOOGLE',
  APPLE: 'APPLE',
};
const SignUp = () => {
  const history = useHistory();
  const classes = useStyles();
  const { isAuth } = useUserData();
  const {} = React.useContext(UserContext);
  const [authType, setAuthType] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const referrerCode = searchParams.get('ref');
  const [visibility, setVisibility] = React.useState({
    password: false,
    confirmPassword: false,
  });

  if (referrerCode) initialValues.referrerCode = referrerCode;

  const { handleSubmit, errors, control } = useForm({
    mode: 'all',
    resolver: yupResolver(authValidation.SIGN_UP),
    defaultValues: initialValues,
  });

  const platform = {
    type: 'WEB',
    device: `${getDeviceDetails()['os.name']} · ${
      getDeviceDetails()['browser.name']
    }`,
  };

  if (!referrerCode && isAuth) history.goBack();
  return (
    <div className="container-xxl">
      <Menu />
      <Paper
        className="p-3 py-5 border mx-auto my-4 rounded-4"
        style={{ maxWidth: 600 }}>
        <div className="mb-3 px-3">
          <div className="row">
            <div className="col-4 fs-xs">Create Account</div>
            <div className="col-4 fs-xs text-center">Verification</div>
            <div className="col-4 fs-xs text-end">Organization Information</div>
          </div>
          <LinearProgress
            classes={{
              root: classes.linear,
              bar: classes.linearBar,
            }}
            variant="determinate"
            value={0}
          />
        </div>
        <div className="mb-4">
          <div className="fs-4 text-center">Get Started</div>
          <div className="text-center fs-sm">
            To get you started, we will need some basic information about you
          </div>
        </div>

        <form
          className="mx-auto"
          onSubmit={handleSubmit(save)}
          style={{ maxWidth: 450 }}>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="mb-3">
                <label>
                  First Name: <span className="text-danger">*</span>
                </label>
                <Controller
                  name="firstName"
                  control={control}
                  render={(props) => (
                    <TextField
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                      helperText={errors['firstName']?.message}
                      error={!!errors['firstName']}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-3">
                <label>
                  Last Name: <span className="text-danger">*</span>
                </label>
                <Controller
                  name="lastName"
                  control={control}
                  render={(props) => (
                    <TextField
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                      helperText={errors['lastName']?.message}
                      error={!!errors['lastName']}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label>
              Email: <span className="text-danger">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              render={(props) => (
                <TextField
                  type="email"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['email']?.message}
                  error={!!errors['email']}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label>
              Phone Number: <span className="text-danger">*</span>
            </label>
            <Controller
              name="phone"
              control={control}
              render={(props) => (
                <PhoneInput
                  country={'ng'}
                  value={`${props.value}`}
                  placeholder="+234 802 220 00"
                  inputProps={{ name: 'phone', required: true }}
                  inputStyle={{ height: '38px', width: '100%' }}
                  onChange={(v) => props.onChange(v)}
                />
              )}
            />

            {errors.phone && (
              <FormHelperText error={errors.phone?.message} className="ms-3">
                {errors.phone?.message}{' '}
              </FormHelperText>
            )}
          </div>
          <div className="mb-3">
            <label>
              Password: <span className="text-danger">*</span>
            </label>
            <Controller
              name="password"
              control={control}
              render={(props) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  type={visibility.password ? 'text' : 'password'}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['password']?.message}
                  error={!!errors['password']}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() => handleChangeVisibility('password')}>
                        {visibility.password ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label>
              Confirm Password: <span className="text-danger">*</span>
            </label>
            <Controller
              name="confirmPassword"
              control={control}
              render={(props) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  type={visibility.confirmPassword ? 'text' : 'password'}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['confirmPassword']?.message}
                  error={!!errors['confirmPassword']}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleChangeVisibility('confirmPassword')
                        }>
                        {visibility.confirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="mb-5">
            <label>Referral Code:</label>
            <Controller
              name="referrerCode"
              control={control}
              render={(props) => (
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="REF123456"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['referrerCode']?.message}
                  error={!!errors['referrerCode']}
                />
              )}
            />

            <div className="">
              <Controller
                name="terms"
                control={control}
                render={(props) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    }
                    label={
                      <span className="d-flex align-items-center">
                        I agree to the{' '}
                        <a
                          target="_blank"
                          href="https://jureb.com/terms"
                          className="text-primary fw-medium ms-1">
                          Terms and Conditions
                        </a>
                      </span>
                    }
                  />
                )}
              />
              {errors?.terms && (
                <FormHelperText error={errors.terms?.message}>
                  Please accept terms and conditions
                </FormHelperText>
              )}
            </div>
          </div>
          <div className="mb-3">
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
              style={{ textTransform: 'none' }}
              disabled={isLoading}
              startIcon={
                isLoading && authType === authTypes.DEFAULT && <BTNLoader />
              }>
              Sign Up
            </Button>
          </div>

          <div className="text-center mb-4">Or</div>
          <div className="mb-3">
            <Button
              fullWidth
              size="medium"
              variant="outlined"
              type="button"
              disabled={isLoading}
              onClick={handleGoogleSignIn}
              style={{ textTransform: 'none' }}
              startIcon={<Google />}
              endIcon={
                isLoading && authType === authTypes.GOOGLE && <BTNLoader />
              }>
              Sign up with Google
            </Button>
          </div>
          <div className="mb-3">
            <Button
              fullWidth
              size="medium"
              variant="outlined"
              type="button"
              disabled={isLoading}
              onClick={handleAppleSignIn}
              className="bg-dark text-white"
              style={{ textTransform: 'none' }}
              endIcon={
                isLoading &&
                authType === authTypes.APPLE && <BTNLoader color="#fff" />
              }
              startIcon={<Apple />}>
              Sign up with Apple
            </Button>
          </div>
          <div className="text-center my-2 mb-4">
            Already have an account?{' '}
            <Link className="text-primary" to={PATHNAMES.SIGN_IN}>
              Sign In
            </Link>
          </div>
        </form>
      </Paper>
    </div>
  );

  async function save(formData) {
    try {
      setAuthType(authTypes.DEFAULT);
      setIsLoading(true);
      await authService.register({ ...formData, platform });
      await requestAccessToken(formData);
      await requestAuthOTP(formData?.email, formData?.referrerCode);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }

  async function requestAccessToken({ email, password }) {
    try {
      const payload = { email, password, platform };
      const { data } = await authService.login(payload);
      saveToken(data?.data);
    } catch (error) {
      const message = `Request could not be completed, please signin to continue.`;
      httpService.toast.error(message);
    }
  }

  async function requestAuthOTP(email, code) {
    let link = PATHNAMES.ACCOUNT_VERIFICATION;
    if (code) link = link + `?referrerCode=${code}`;
    try {
      await authService.requestAuthOTP({ email });
      location.replace(link);
    } catch (error) {
      console.error('OTP REQUEST ERROR: ', error);
    } finally {
      location.replace(link);
    }
  }

  function handleChangeVisibility(name) {
    setVisibility((prev) => ({ ...prev, [name]: !prev[name] }));
  }

  function handleGoogleSignIn() {
    setAuthType(authTypes.GOOGLE);
    googleSignIn(setIsLoading, true);
  }
  function handleAppleSignIn() {
    setAuthType(authTypes.APPLE);
    appleSignIn(setIsLoading, true);
  }
};

export default SignUp;
