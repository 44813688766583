import { UserContext } from 'context/user/user';
import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import saveToken from 'utils/saveToken';

export default function Home() {
  const history = useHistory();
  const { accessToken } = React.useContext(UserContext);
  const searchParams = new URLSearchParams(window.location.search);

  const token = searchParams.get('token');
  const referralCode = searchParams.get('ref');
  const redirect = searchParams.get('redirect');
  const organization_id = searchParams.get('organization_id');

  if (redirect && token && organization_id) {
    saveToken({ accessToken: token });
    localStorage.setItem('organizationId', organization_id);
    window.location.replace(redirect);
  } else {
    if (accessToken) window.location.replace(PATHNAMES.ORGANIZATIONS);
    else if (redirect === 'invitation') {
      window.location.replace(PATHNAMES.NEW_INVITES);
    } else if (referralCode) {
      window.location.replace(`${PATHNAMES.SIGN_UP}?ref=${referralCode}`);
    } else window.location.replace(PATHNAMES.SIGN_IN);
  }

  return null;
}

// '?token=123&redirect=/dashboard/billing&organization_id=123';
